body {
  top: 0px;
  left: 0px;
  font-family: 'Poppins', sans-serif;
}


.rccs__card--unknown > div {
  background: linear-gradient(25deg, #E3303D, #E3303D) !important;;
  box-shadow: none;
}

.credit-card-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Üst kısımda hizalamak için */
  padding: 20px;
}

.credit-card-form form {
  margin-top: 20px; 


}

.credit-card-form input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

.slick-slider {
  background-color: #fff;
}

.slider-container {
  position: relative;
  width: 1330px;
  height: 430px;
  overflow: hidden;
  margin-bottom: 5%;
}

.slider-container img {
  display: block;
  width: 100%;
  height: 430px;
  /* Adjust height as per the aspect ratio */
}

.text-red {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: #E3303D;
}

.text-normal-15 {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  color: #2D2D2D;
}

.text-normal-15-gray {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  color: #EEEEEE;
}

.text-bold-normal-15 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
}

.text-bold-normal-25 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 25px;
  color: #000000;
}

.prductcard-quantity-selector{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f28b82;
  border-radius: 5px;
  padding: 5px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  width: 80%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center; 
}

.prductcard-quantity-selector img {
  cursor: pointer;
  width: 20px;
  /* Adjust the size of the icons */
  height: 20px;
}


.prductcard-quantity-selector .col-2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFE4E4;
  height: 35px;
  margin: -5px;
  border-radius: 5px;
}

.prductcard-quantity-selector .col-8 {
  flex: 2;
  text-align: center;
}

.prductcard-quantity-selector b {
  font-size: 1rem;
  color: #000;
}


.basketaddedbutton {
  width: 193px;
  height: 45px;
 
}

.basketaddedbutton img {
  width: 20px;
  background-color: "#E3303D";
  cursor: "pointer"
}

.solbtn {
  background-color: "#FFE4E4";

}

.quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f28b82;
  border-radius: 5px;
  padding: 5px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  width: 193px;
  display: flex;
  justify-content: end;
  justify-items: center;
  align-items: center; 
}

.quantity-selector img {
  cursor: pointer;
  width: 20px;
  /* Adjust the size of the icons */
  height: 20px;
}

.quantity-selector .col-2 {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFE4E4;
  height: 43px;
  margin: -5px;
  border-radius: 5px;
}

.quantity-selector .col-8 {
  flex: 2;
  text-align: center;
}

.quantity-selector b {
  font-size: 1rem;
  color: #000;
}


.image-container {
  display: flex; /* İçerikleri yatay olarak hizalamak için flex kullanıyoruz */
  position: relative; /* Resimlerin konumunu ayarlamak için kullanacağız */
  margin-bottom: 40px;
  margin-top: 20px;
}

.image-container img {
  position: absolute; /* Resimlerin konumunu ayarlamak için */
}

.image-container img:first-child {
  margin-left: 195px; /* İlk resmi 5 piksel sola kaydır */
}

.text-baslik {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 25px;
  color: #302F2F;
}

#dropdown-basic {
  background-color: #EFEFEF;
  color: #000;
  border-width: 0px;
}

#dropdown-lang {
  background-color: white;
  color: #000;
  border-width: 0px;
}

#dropdown-stores {
  background-color: #FFFFFF;
  color: #000;
  border-width: 1px;
  border-color: #C7C7C7;
  border-radius: 5px;
  max-width: 100%;

}

.loginbox {
  margin-top: -13%;
}

.successbox {
  margin-top: -16%;
}

.register-border {
  background-color: #FFFFFF;
  color: #000;
  border-color: #C7C7C7;
  border: 1px solid #C7C7C7;
  margin-bottom: 500px;
}

.success-border {
  background-color: #FFFFFF;
  color: #000;
  border-color: #C7C7C7;
  border: 1px solid #C7C7C7;
  margin-bottom: 300px;
  width: 50%;
}
.newadressborder{
  border: 1px solid #E4211F;
  color: #E4211F;
  margin-top: 20px;
}

.orderborder {
  background-color: #E4211F;
  border: 1px solid #E4211F;
  color: white;
  justify-content: center;
  width: 119px;
  font-size: 12px;
  margin-top: 5px;
}

#checkbox-register {
  color: #000;
  border: 1px solid black;
}

.adresbar {
  height: 38px;
  background-color: #FFFFFF;
  color: #000;
  border: 1px solid #C7C7C7;
  border-radius: 5px;
}

#selepetim {
  background-color: #FFFFFF;
  color: #000;
  border-width: 1px;
  border-color: #C7C7C7;
}

.sepetimbtn {
  margin-left: -10px;
  margin-top: -20px;
  font-size: 9px;
  margin-top: -9px;

}

.position-relative {
  position: relative;
  /* Badge'in konumlandırılması için gerekli */
}

.btn .badge {
  position: relative;
  top: -9px;
}



@media (max-width: 767.98px) {
  #selepetim {
    width: 100%;
  }

  .loginbox {
    margin-top: -60%;
  }

  .register-border {
    background-color: #FFFFFF;
    color: #000;
    border-color: #C7C7C7;
    border: 1px solid #C7C7C7;
  }
}

#dropdown-stores{
  width: 500px;
}

#sliderdButton {
  border-width: 0px;
  width: 44px;
  height: 44px;
}


.navbar-horizontal-line {
  border: none;
  height: 10px;
  background-color: red;
  width: 100%;
}


.navbar-logo {
  width: 238px;
  height: 57px;
}

.kaydol-btn {
  border: none;
  width: 101px;
  height: 48px;
  /* UI Properties */
  background: #E32835 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #FFFFFF;
  opacity: 0.92;
  opacity: 0.92;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.girisyap-btn {
  width: 101px;
  height: 48px;
  border: 1px solid #E32835;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #E32835;
  opacity: 0.92;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.degistir-btn {
  width: 101px;
  height: 48px;
  border: 1px solid #E32835;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #E32835;
  opacity: 0.92;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.languages {

  width: 23px;
  height: 28px;
  text-align: left;
  font: normal normal 600 20px/30px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.serachbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 2%;
}

.serachbar input {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C7C7C7;
  border-radius: 6px;
  opacity: 1;
  width: 60%;
  height: 48px;
}

.serachbar .dropdown {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C7C7C7;
  border-radius: 6px;
  opacity: 1;
  width: 15%;
  height: 48px;
}

.navbar-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 2%;
}

.custom-button {
  background-color: #FFFFFF;
  color: black;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  transition: color 0.3s;
  text-align: left;
  font: normal normal bold 14px/32px Poppins;
  letter-spacing: 0px;
  opacity: 0.92;
}

.gosterge{
  width: 249px;
  height: 53px;
  background-color: #E3303D;
  border-radius: solid 0.0.90.90;
}


.custom-button::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: red;
  transition: width 0.3s;
  position: absolute;
  bottom: -5px;
  left: 0;
  color: #E32835;
}

.custom-button.active::after {
  width: 100%;
}

.custom-button.active {
  color: #E32835; /* Text color when active */
}

.last-button {
  width: 191px;
  height: 44px;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border: 1px solid #EFEFEF;
  border-radius: 7px;
  opacity: 1;
  font: normal normal bold 14px/32px Poppins;
  letter-spacing: 0px;
  opacity: 0.92;
  padding: 10px 20px;
  font-size: 16px;
  align-items: center;
  align-self: center;
  font-weight: bold;
}

.custom-button:hover {
  color: #E32835;
}

.custom-button:hover::after {
  width: 100%;
}

.custom-dropdown .dropdown-toggle {
  width: 100%;
  height: 48px;
  background-color: #FFFFFF;
  color: black;
  border: 1px solid #C7C7C7;
}

.custom-dropdown .dropdown-toggle:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.custom-dropdown .dropdown-menu {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
}

.custom-dropdown .dropdown-item {
  color: #343a40;
}

.custom-dropdown .dropdown-item:hover {
  background-color: #e9ecef;
  color: #007bff;
}


/* src/components/Slider.css */
.slider-container {
  border-radius: 10px;
}

.slick-slide {
  text-align: center;
  height: 430px;
  line-height: 430px;
  background: #ddd;
  color: #333;
  font-size: 24px;
  border-radius: 10px;

}

.slick-prev,
.slick-next {
  background-color: #007bff;
  color: white;
  padding: 10px;
  z-index: 1;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #0056b3;
}

.slider-dots {
  display: flex !important;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.slider-dots li button {
  margin: 20px 5px;
  background-color: red;
  width: 50px;
  height: 10px;
  border-radius: 10px;
  border-color: red;
  color: white;

}

.slider-dots li button:before {
  font-size: 12px;
  color: white;
  opacity: 1;
}

.slider-dots li.slick-active button::after {
  color: black;
  opacity: 1;
  display: none;
}

.slider-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20%;
  margin-left: 20%;
}

/*slider product*/

.banner {
  display: flex;
  flex-direction: column;
  margin-top: -70px;
  border-radius: 10px;


}


.item-list-container {
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: 0px;
  width: 50%;
}

.item-list {
  display: flex;
  overflow-x: scroll;
  /* Yatay kaydırma çubuğunu etkinleştirir */
  overflow-y: hidden;
  /* Dikey kaydırma çubuğunu gizler */
  white-space: nowrap;
  scrollbar-width: none;
  /* Firefox için yatay kaydırma çubuğunu gizler */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ için yatay kaydırma çubuğunu gizler */
  border-radius: 10px;
}

/* Webkit tabanlı tarayıcılar (Chrome, Safari) için yatay kaydırma çubuğunu gizler */
.item-list::-webkit-scrollbar {
  display: none;
}

.item-list>* {
  flex: 0 0 auto;
  /* Flex öğelerinin küçülmesini veya büyümesini önler */
}

.item {
  margin: 5px;
  /* background-color: #ccc; */
  border: 1px solid #E7E7E7;
  border-radius: 10px;
  width: 170px;
  height: 300px;
}